var gptwGA = {
	event: function (category = null, action = null, label = null, value = null) {
		if (category === null && action === null) {
			console.log('GPTW: no category or action passed to gptwGA.event');
			return false;
		}

		if (typeof ga === 'function') {
			//send event for analytics.js
			//https://developers.google.com/analytics/devguides/collection/analyticsjs/ga-object-methods-reference
			ga(function () {
				let trackers = ga.getAll();
				let ids = [];
				var id;

				//for each of the trackers on the page
				//https://developers.google.com/analytics/devguides/collection/analyticsjs/tracker-object-reference
				trackers.forEach(function (tracker) {
					id = tracker.get('trackingId');

					//only trigger once per UA id
					if (!ids.includes(id)) {
						ids.push(tracker.get('trackingId'));

						tracker.send('event', category, action, label, value);
					}
				});
			});

			return true;
		} else {
			console.log('GPTW: ga function not detected by gptwGA.event');
		}

		//https://developers.google.com/analytics/devguides/collection/gajs/eventTrackerGuide
		if (typeof _trackEvent !== 'undefined') {
			_trackEvent(category, action, label, value);
			return true;
		}

		return false;
	},
};

var gptwGA4 = {
	event: function (name, data) {
		if (typeof gtag === 'function') {
			window.gtag('event', name, data);
		}
	},
};

var linkTrackingElements = document.querySelectorAll('.module-link-tracking');

linkTrackingElements.forEach((el) => {
	const links = el.getElementsByTagName('a');

	[...links].forEach((link) => {
		if (link.href !== '#') {
			link.addEventListener('click', (e) => {
				e.preventDefault();

				gptwGA.event('CTA click', el.dataset.moduleName, e.target.href);
				gptwGA4.event('cta_click', { module_name: el.dataset.moduleName, url: e.target.href });

				if (typeof e.target.target !== 'undefined' && e.target.target === '_blank') {
					window.open(e.target.href, '_blank');
				} else {
					window.location.href = e.target.href;
				}
			});
		}
	});
});
